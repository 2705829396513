@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);

@mixin base-font {
  font-family: 'Source Sans Pro', sans-serif;
}

@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.material-icons {
  width: 1em;
  height: 1em;
  font-size: 1em;
  line-height: 1;
}