$instance-id: 'pv' !default;
$instance-name: 'PV Tools' !default;

$theme-color-1: #40D2C1 !default;
$theme-color-2: #00B09B !default;
$theme-color-3: #8FE2D5 !default;
$theme-color-4: #404241 !default;

$color-1: #BEBEBE;
$color-2: #DBEBEB;
$color-3: #f6f6f6;
$color-4: #AFAFAF;
$color-5: #9D9D9D;

$color-regiosuisse: #a9cc79;
$color-chmos: #cc5754;
$color-blw: #b3ce46;
$color-xls: #1dcc54;

$error: #C00;
$warning: #cb8a00;
$success: #0A0;

$white: #FFF;
$black: #000;

$small-breakpoint: 1520px;
$tablet-breakpoint: 1080px;
$mobile-breakpoint: 720px;